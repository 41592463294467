import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import SectionContact from '../components/section-contact'

export default function AboutPage({ data, location }) {
  const { wordpressPage } = data
  const { acf } = wordpressPage
  const {
    header,
    seo,
    open_graph,
    twitter,
    first_body,
    difference,
    process,
    contact_section,
  } = acf

  return (
    <Layout location={location.pathname}>
      <Helmet
        title={seo.title}
        meta={[
          { name: 'description', content: seo.description },
          { name: 'og:title', content: open_graph.title },
          { name: 'og:description', content: open_graph.description },
          { name: 'og:image', content: open_graph.image.source_url },
          { name: 'og:url', content: 'https://www.modinfinity.com/about' },
          { name: 'twitter:title', content: twitter.title },
          { name: 'twitter:description', content: twitter.description },
          { name: 'twitter:image', content: twitter.image.source_url },
          { name: 'twitter:card', content: twitter.card_size },
        ]}
      />
      <section className="container mx-auto md:pt-20 md:pb-10 px-2">
        <div className="mb-4 md:mb-12 py-10">
          <h1 className="font-black text-4xl md:text-5xl lg:text-6xl xxl:text-7xl text-indigo-darkest md:text-center">
            {header.title}
          </h1>
        </div>
        <div className="md:w-3/4 xl:w-3/5 xxl:w-1/2 md:mx-auto">
          <p className="text-xl text-indigo-darkest font-light leading-normal">
            {first_body}
          </p>
        </div>
      </section>
      <section className="container mx-auto mt-8 md:py-10 px-2">
        <div className="mb-4 xl:mb-8 py-6">
          <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-indigo-darkest font-black md:text-center">
            {difference.title}
          </h2>
        </div>
        <div className="md:w-3/4 xl:w-3/5 xxl:w-1/2 md:mx-auto">
          <p className="text-xl text-indigo-darkest font-light leading-normal">
            {difference.copy}
          </p>
        </div>
      </section>
      <section className="container mx-auto mt-8 md:py-10 px-2">
        <div>
          <div className="mb-8 py-6">
            <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-indigo-darkest font-black md:text-center">
              {process.title}
            </h2>
          </div>
          <div>
            {process.processes.map(process => (
              <div className="mb-8 lg:my-24 xl:my-44 lg:flex lg:flex-row-reverse lg:items-center">
                <div className="md:w-2/3 lg:w-2/5 md:mx-auto mb-4 lg:shadow-md">
                  <Img
                    fluid={
                      process.process_image.localFile.childImageSharp.fluid
                    }
                    alt={process.process_image.alt_text}
                  />
                </div>
                <div className="md:w-3/4 lg:w-3/5 xxl:w-1/2 md:mx-auto lg:pr-4">
                  <h3 className="uppercase text-2xl lg:text-4xl xxl:text-5xl font-black text-indigo-darkest text-left">
                    {process.process_title}
                  </h3>
                  <p className="my-3 text-sm text-indigo-light font-extrabold uppercase">
                    {process.process_sub_title}
                  </p>
                  <p className="text-xl text-indigo-darkest font-light leading-normal">
                    {process.process_copy}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <SectionContact contact={contact_section} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "about" }) {
      acf {
        header {
          title
        }
        first_body
        difference {
          title
          copy
        }
        process {
          title
          processes {
            process_title
            process_sub_title
            process_copy
            process_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        contact_section {
          title
          call_to_action
          illustration
          illustration_position
        }
        seo {
          title
          description
        }
        open_graph {
          title
          description
          image {
            source_url
          }
        }
        twitter {
          title
          description
          card_size
          image {
            source_url
          }
        }
      }
    }
  }
`
